import { Component, ElementRef, OnInit, ViewChild, viewChild} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs, { init } from 'emailjs-com';


@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    contactForm: FormGroup;
    
    // activeSection: string = 'home'; 

    constructor(private viewportScroller: ViewportScroller, private router: Router, private fb: FormBuilder) {
        this.contactForm = this.fb.group({
        });
    }  
      currentSlideIndex = 0;
      isBulged(index: number) {
        return index === this.currentSlideIndex;
      }

    ngOnInit(): void { 
        // this.initOwlCarousel();
        this.contactForm = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.email, Validators.required]],
            phone_number: [null, [Validators.required]],
            msg_subject: [null, [Validators.required]],
            message: [null, [Validators.required]],
            contactType: ['contactUs']
        });
      
    }
    async send() {
        if (this.contactForm.valid) {
            // emailjs.init('XfBFkjN4zr0wLflCL'); 
            emailjs.init('ucmftdNCusykTLpcR');
            
            // let response = await emailjs.send("service_jzjzj0k", "template_drieylk", {
                let response = await emailjs.send("service_n7kupbf", "template_4iexeo7", {
                name: this.contactForm.value.name,
                message: this.contactForm.value.message,
                email: this.contactForm.value.email,
                phone_number: this.contactForm.value.phone_number,
                msg_subject: this.contactForm.value.msg_subject,
                contactType : this.contactForm.value.contactType
            });
    
            alert('Message sent successfully');
            this.contactForm.reset();
        } else {
            alert('Form is not valid. Please check your inputs.');
        }
    }
    
    scrollToNextSection() {
        const nextSection = document.getElementById('aboutSection');
        if (nextSection) {
            this.viewportScroller.scrollToAnchor('aboutSection');
        }
    }
    scrollToContactSection() {
        const contactSection = document.getElementById('contactSection');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }
   
    teamSlides: OwlOptions = {
        loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    } 

    // Accordion
    accordionItems = [
        {
            title: 'Grow Online Sales',
            content: `Attract qualified leads and convert them into paying customers through user-friendly, SEO-optimized websites.`,
            open: false
        },
        {
            title: 'Automate & Gain Edge',
            content: `Streamline tasks, unlock efficiency and outpace the competition with automated solutions.`,
            open: false
        },
        {
            title: 'Scale Securely, Save',
            content: `Adapt with ease, protect your data and optimize costs with secure cloud solutions.`,
            open: false
        },
        {
            title: 'Target, Engage, Convert',
            content: `Reach your ideal audience, build meaningful connections and generate qualified leads.`,
            open: false
        }
    ];
    selectedItem: any = null;
    toggleAccordionItem(item: any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    imageCarouselOptions: OwlOptions = {
        loop: true,
        // margin: 20,
        nav: false,
        items: 1,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                // margin: 10,
            },
            400: {
                items: 2,
            },
            800: {
                items: 3,
                // margin: 20,
            },
            1200: {
                items: 4,
            }
        }
    };


    changeContactType() {
        if (this.contactForm.get('contactType').value === 'getQuote') {
          this.contactForm.get('contactType').setValue('contactUs');
        } else {
          this.contactForm.get('contactType').setValue('getQuote');
        }
      }

    //   ----------Lazy Load img

    landingImg = '../../../../assets/images/vector image/landing page - compressed2.png'
    shape1 = 'assets/images/shape/1.png'
    shape2 = 'assets/images/shape/2.png'
    shape3 = 'assets/images/shape/3.png'
    shape4 = 'assets/images/shape/4.png'
    shape5 = 'assets/images/shape/5.png'
    shape6 = 'assets/images/shape/6.png'
    about = '../../../../assets/images/about-img3 - compressed.jpg'
    service1 = 'assets/images/service/s1.png'
    service2 = 'assets/images/service/s2.png'
    service3 = 'assets/images/service/s3.png'
    service4 = 'assets/images/service/s4.png'
    service5 = 'assets/images/service/s5.png'
    service6 = 'assets/images/service/s6.png'
    idea_sharing = '../../../../assets/images/vector image/idea sharing - compressed.png'
    technology1 = '../../../../assets/images/techNoBg/1.png'
    technology2 = '../../../../assets/images/techNoBg/2.png'
    technology3 = '../../../../assets/images/techNoBg/3.png'
    technology4 = '../../../../assets/images/techNoBg/4.png'
    technology5 = '../../../../assets/images/techNoBg/5.png'
    technology6 = '../../../../assets/images/techNoBg/6.png'
    consulting = 'assets/images/process/p1.png'
    planning = 'assets/images/process/p2.png'
    executing = 'assets/images/process/p3.png'
    supporting = 'assets/images/process/p4.png'
    next = 'assets/images/process/next.png'
    partnership = '../../../../assets/images/vector image/dedicated partnership - compressed.png'
    contact = 'assets/images/contact-img.png'
}