import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class NavbarComponent implements OnInit {

  location: any;
  navbarClass: any;
  showAboutSection: boolean = false;
  activeSectionId: string | null = null;
  classApplied = false;

  isAboutClicked: boolean = false;
  isServicesClicked: boolean = false;
  isWhyUsClicked: boolean = false;
  isTestimonialsClicked: boolean = false;
  isContactClicked: boolean = false;
  isHomeClicked: boolean =false;
  // elementRef: any;
  activeSection: string = 'home';

  
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  constructor(
    private router: Router,
    location: Location,
    private viewportScroller: ViewportScroller,
    private elementRef: ElementRef

  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.location = this.router.url;
          if (this.location == '/home-three') {
            this.navbarClass = 'navbar-area three';
          } else {
            this.navbarClass = 'navbar-area';
          }
        }
      });
  }
  
ngOnInit(): void {
}

scrollToHomeSection(){
  this.router.navigate(['/home']).then(() => {
    setTimeout(() => {
      const contactSection = document.getElementById('homeSection');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 0);
  });
  this.isAboutClicked = false;
  this.isServicesClicked = false;
  this.isWhyUsClicked = false;
  this.isTestimonialsClicked = false;
  this.isContactClicked = false;
  this.isHomeClicked = true;
}

  scrollToServicesSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const servicesSection = document.getElementById('servicesSection');
        if (servicesSection) {
          servicesSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
    this.isAboutClicked = false;
    this.isServicesClicked = true;
    this.isWhyUsClicked = false;
    this.isTestimonialsClicked = false;
    this.isContactClicked = false;
    this.isHomeClicked = false;
  }
  scrollToAboutSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const aboutSection = document.getElementById('aboutSection');
        if (aboutSection) {
          aboutSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);

    });
    this.isAboutClicked = true;
    this.isServicesClicked = false;
    this.isWhyUsClicked = false;
    this.isTestimonialsClicked = false;
    this.isContactClicked = false;
    this.isHomeClicked = false;
  }
  scrollToWhyUsSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const whyUsSection = document.getElementById('whyUsSection');
        if (whyUsSection) {
          whyUsSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
    this.isAboutClicked = false;
    // Reset other click states
    this.isServicesClicked = false;
    this.isWhyUsClicked = true;
    this.isTestimonialsClicked = false;
    this.isContactClicked = false;
    this.isHomeClicked = false;
  }
  scrollToTestimonialsSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const testimonialsSection = document.getElementById('testimonialsSection');
        if (testimonialsSection) {
          testimonialsSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
    this.isAboutClicked = false;
    // Reset other click states
    this.isServicesClicked = false;
    this.isWhyUsClicked = false;
    this.isTestimonialsClicked = true;
    this.isContactClicked = false;
    this.isHomeClicked = false;
  }
  scrollToContactSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const contactSection = document.getElementById('contactSection');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
    this.isAboutClicked = false;
    // Reset other click states
    this.isServicesClicked = false;
    this.isWhyUsClicked = false;
    this.isTestimonialsClicked = false;
    this.isContactClicked = true;
    this.isHomeClicked = false;
  }
  
  logoRefresh(event: Event){
    event.preventDefault();
    window.location.reload();
}

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

}

