<section class="banner-area" id="homeSection">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Empower your business for the digital age</h1>
                            <p>Discover how our IT solutions can empower your business to achieve its full potential.
                            </p>
                            <!-- <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Get Started</a>
                            </div> -->
                            <div class="banner-btn">
                                <a (click)="scrollToNextSection()" class="box-btn">Get Started</a>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <!-- <img src="../../../../assets/images/vector image/landing page - compressed2.png" alt="banner-img"/> -->
                            <img [lazyLoad]="landingImg" alt="banner-img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" ></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape" ></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" ></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" ></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" ></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" ></div>

        <!-- <div class="shape1"><img [lazyLoad]="shape1" alt="shape" ></div>
        <div class="shape2"><img [lazyLoad]="shape2" alt="shape" ></div>
        <div class="shape3"><img [lazyLoad]="shape3" alt="shape" ></div>
        <div class="shape4"><img [lazyLoad]="shape4" alt="shape" ></div>
        <div class="shape5"><img [lazyLoad]="shape5" alt="shape" ></div>
        <div class="shape6"><img [lazyLoad]="shape6" alt="shape" ></div> -->
    </div>
</section>

<section id="aboutSection" class="pt-50 bg-color"></section>
<section  class="home-company-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <!-- <span>Our Company</span> -->
                        <h2>About Us</h2>
                        <p style="text-align: justify;">
                            Empowering businesses with <b>cutting-edge technology and strategic data solutions,</b> Geeniti
                            Systems Pvt Ltd is dedicated to reshaping operations and ensuring sustained success in the
                            ever-evolving digital landscape. Our innovative approach, at the forefront of tomorrow's
                            solutions, transforms unique business needs into <b>tailored software excellence, spanning
                                internal management, websites, mobile applications and a spectrum of additional
                                services.</b>
                        </p>
                        <p style="text-align: justify;">
                            Committed to excellence, we foster a collaborative environment, propelling our clients into
                            a future of endless possibilities.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-1">

            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <!-- <img src="../../../../assets/images/about-img3 - compressed.jpg" alt="company"/> -->
                    <img [lazyLoad]="about" alt="company"/>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- pt-130 pb-70 -->
<section id="servicesSection" class="pt-50"></section>
<!-- home-service-area -->
<section  class=" pt-100 pb-30 ">
    <div class="container">
        <div class="section-title">
            <h2>Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s1.png" width="60" alt="service" /> -->
                        <img [lazyLoad]="service1" width="60" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Website Development</h3>
                        <p>Craft user-friendly and results-driven websites that elevate your online presence.</p>
                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s2.png" width="60" alt="service" /> -->
                        <img [lazyLoad]="service2" width="60" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Mobile App Development</h3>
                        <p>Build innovative mobile apps to engage users, streamline processes and achieve your goals.
                        </p>
                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s3.png" width="60" alt="service" /> -->
                        <img [lazyLoad]="service3" width="60" alt="service" /> 
                    </div>

                    <div class="service-content">
                        <h3>Software Development</h3>
                        <p>Deliver custom software solutions that automate tasks, boost efficiency and meet your
                            specific needs.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s4.png" width="60" alt="service"  /> -->
                        <img [lazyLoad]="service4" width="60" alt="service"  />
                    </div>

                    <div class="service-content">
                        <h3>Cloud Solutions</h3>
                        <p>Navigate the cloud landscape, leverage its scalability and security and optimize your IT
                            infrastructure.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s5.png" width="60" alt="service" /> -->
                        <img [lazyLoad]="service5" width="60" alt="service"  />
                    </div>

                    <div class="service-content">
                        <h3>Digital Marketing</h3>
                        <p>Develop data-driven strategies to reach your target audience, build brand awareness and
                            generate leads.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <!-- <img src="assets/images/service/s6.png" width="60" alt="service" /> -->
                        <img [lazyLoad]="service6" width="60" alt="service"  />
                    </div>

                    <div class="service-content">
                        <h3> IT Support & Maintenance</h3>
                        <p>Ensure your technology runs smoothly with proactive monitoring, troubleshooting and ongoing
                            support.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- pt-150 pb-100 -->

<section class="choose-area pt-100 pb-30">
    <div class="container">
        <div class="section-title">
            <!-- <span>Choose Aiva</span> -->
            <h2>Address Your Business Needs</h2>
            <p>Tap into our expertise to streamline operations, elevate your marketing and propel your business growth.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <!-- <img src="../../../../assets/images/vector image/idea sharing - compressed.png" alt="choose" /> -->
                    <img [lazyLoad]="idea_sharing" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="imageCarouselSection" class="pt-50"></section>
<section  class="pt-100 pb-30  container-fluid ">
    <div class="container">
        <div class="section-title">
            <h2>We work with</h2>
        </div>
        <div class="image-carousel-wrap p-5">
            <owl-carousel-o [options]="imageCarouselOptions">
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/1.png" height="150" width="150" alt="Image 1" > -->
                        <img [lazyLoad]="technology1" height="150" width="150" alt="Image 1" >
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/2.png" height="150" width="150" alt="Image 6" > -->
                        <img [lazyLoad]="technology2" height="150" width="150" alt="Image 2" >
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/3.png" height="150" width="150" alt="Image 2" > -->
                        <img [lazyLoad]="technology3" src="../../../../assets/images/techNoBg/3.png" height="150" width="150" alt="Image 2" >
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/4.png" height="150" width="150" alt="Image 3" > -->
                        <img [lazyLoad]="technology4" src="../../../../assets/images/techNoBg/4.png" height="150" width="150" alt="Image 3" >
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/5.png" height="150" width="150" alt="Image 4" > -->
                        <img [lazyLoad]="technology5" src="../../../../assets/images/techNoBg/5.png" height="150" width="150" alt="Image 4" >
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slide">
                        <!-- <img src="../../../../assets/images/techNoBg/6.png" height="150" width="150" alt="Image 5" > -->
                        <img [lazyLoad]="technology6" src="../../../../assets/images/techNoBg/6.png" height="150" width="150" alt="Image 5" >
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<!-- pt-200 pb-70 -->
<section id="whyUsSection" class="pt-50"></section>
<section  class="home-process-area pt-100 pb-30">
    <div class="container">
        <div class="section-title">
            <h2>Your 4-Step Path to Digital Success </h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="iconDiv">
                        <div class="icon">
                            <!-- <img src="assets/images/process/p1.png" alt="consulting" /> -->
                            <img [lazyLoad]="consulting" alt="consulting" />
                            <span>
                                <!-- <img src="assets/images/process/next.png" alt="shape" /> -->
                                <img [lazyLoad]="next" alt="shape" />
                            </span>
                        </div>
                        <h3 class="process-heading">Consulting</h3>
                    </div>
                    
                    <div class="content">
                        <h3>Consulting</h3>
                        <p>We start with in-depth consultations, thoroughly understanding your unique business goals and
                            challenges.
                        </p>
                    </div>
                </div>
            </div>
           

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="iconDiv" style="padding-right: 20px;">
                        <div class="icon">
                            <!-- <img src="assets/images/process/p1.png" alt="Planning" /> -->
                            <img [lazyLoad]="planning" alt="Planning" />
                            <span>
                                <!-- <img src="assets/images/process/next.png" alt="shape" /> -->
                                <img [lazyLoad]="next" alt="shape" />
                            </span>
                        </div>
                    <h3 class="process-heading">Planning</h3>
                </div>
                    <div class="content">
                        <h3>Planning</h3>
                        <p>Our experts collaborate with you to craft a comprehensive plan, outlining specific solutions
                            and technologies tailored to your needs.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="iconDiv" style="padding-right: 20px;">
                        <div class="icon">
                            <!-- <img src="assets/images/process/p1.png" alt="Executing" /> -->
                            <img [lazyLoad]="executing" alt="Executing" />
                            <span>
                                <!-- <img src="assets/images/process/next.png" alt="shape" /> -->
                                <img [lazyLoad]="next" alt="shape" />
                            </span>
                        </div>
                        <h3 class="process-heading">Executing</h3>
                    </div>

                    <div class="content">
                        <h3>Executing</h3>
                        <p>We seamlessly implement your plan, ensuring efficient integration and minimal disruption to
                            your daily operations.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="iconDiv">
                        <div class="icon">
                            <!-- <img src="assets/images/process/p4.png" alt="supporting" /> -->
                            <img [lazyLoad]="supporting" alt="supporting" />
                        </div>
                        <h3 class="process-heading">Supporting</h3>
                    </div>

                    <div class="content">
                        <h3>Supporting</h3>
                        <p>We provide ongoing support, monitoring and optimization to ensure your continued success in
                            the ever-evolving digital landscape.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area bg-color pt-100 pb-30">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <!-- <span>Features</span> -->
                        <h2>Unmatched Value & Exceptional Service</h2>
                        <p>We stand out from the crowd by delivering on our promise of excellence through:</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Dedicated Partnership</li>
                        <li><i class="flaticon-correct"></i> Tailored Solutions</li>
                        <li><i class="flaticon-correct"></i> Data-Driven Approach</li>
                        <li><i class="flaticon-correct"></i> Unwavering Commitment</li>
                        <li><i class="flaticon-correct"></i> Continuous Learning</li>
                    </ul>
                    <a (click)="scrollToContactSection(); changeContactType()" class="box-btn">Get Quote</a>


                </div>
            </div>

            <div class="col-lg-6  hidden-on-mobile">
                <div class="feature-img">
                    <!-- <img src="../../../../assets/images/vector image/dedicated partnership - compressed.png" alt="feature" /> -->
                    <img [lazyLoad]="partnership" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>


<!-- pt-130 pb-100 -->
<section id="contactSection" class="pt-50 bg-color"></section>
<section  class="home-contact-area bg-color pt-100 pb-30">
    <div class="container">
        <div class="section-title">
            <h2>Let Us Know About Your Project Idea!</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <!-- <img src="assets/images/contact-img.png" alt="contact" > -->
                    <img [lazyLoad]="contact" alt="contact" >
                    
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" (ngSubmit)="send()" [formGroup]="contactForm">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" required
                                placeholder="Your Name" formControlName="name">
                            <small *ngIf="contactForm.controls['name'].invalid && contactForm.controls['name'].touched"
                                class="text-danger">
                                Name is required.
                            </small>
                        </div>

                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" required
                                placeholder="Your Email" formControlName="email">
                            <small
                                *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched"
                                class="text-danger">
                                Please enter a valid email address.
                            </small>
                        </div>

                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" required class="form-control"
                                placeholder="Your Phone with Contact Code" formControlName="phone_number">
                            <small
                                *ngIf="contactForm.controls['phone_number'].invalid && contactForm.controls['phone_number'].touched"
                                class="text-danger">
                                Phone number is required.
                            </small>
                        </div>

                        <div class="form-group custom-select">
                            <select name="contactType" id="contactType" class="form-control" formControlName="contactType">
                                <option value="contactUs">Contact Us</option>
                                <option value="getQuote">Get Quote</option>
                            </select>
                            <div class="select-arrow"></div>
                        </div>
                        

                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                placeholder="Subject" formControlName="msg_subject">
                            <small
                                *ngIf="contactForm.controls['msg_subject'].invalid && contactForm.controls['msg_subject'].touched"
                                class="text-danger">
                                Subject is required.
                            </small>
                        </div>

                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" required
                                placeholder="Your Message" formControlName="message"></textarea>
                            <small
                                *ngIf="contactForm.controls['message'].invalid && contactForm.controls['message'].touched"
                                class="text-danger">
                                Message is required.
                            </small>
                        </div>

                        <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-50 bg-color"></section>