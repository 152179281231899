<footer class="footer-area pt-100" loading="lazy">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/">
                            <img [lazyLoad]="logo" height="75px" width="150px" alt="logo"/>
                        </a>
                    </div>
                    <p style="text-align: justify; padding-right: 20px;">The internet was originally developed as a way for scientists to share information during the Cold War. Today, it connects billions of people across the globe and has fundamentally changed how we live, work and communicate.</p>

                
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Website Development</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Mobile App Development</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Software Development</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Cloud Solutions</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Digital Marketing</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">IT Support & Maintenance</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a (click)="scrollToHomeSection()">Home</a></li>
                        <li class="nav-item">
                            <a (click)="scrollToAboutSection()" class="nav-link">About</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToServicesSection()" class="nav-link">Services</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="scrollToWhyUsSection()" class="nav-link">Why Us</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a (click)="scrollToTestimonialsSection()" class="nav-link">Testimonials</a>
                        </li> -->
                        
                        <li class="nav-item">
                            <a (click)="scrollToContactSection()" class="nav-link">Contact</a>
                        </li>      
                        <li class="nav-item">
                            <a class="nav-link">Privacy & Policy</a>
                        </li>  
                        <li class="nav-item">
                            <a class="nav-link">Terms & Conditions</a>
                        </li>  
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+919585911111"><i class="bx bx-phone"></i>+91 95859 11111</a></li>
                        <li><a href="mailto:email@example.com"><i class="bx bxs-envelope"></i>info.geeniti&#64;gmail.com </a></li>
                        <li><i class="bx bxs-map"></i><a target="_blank" href="https://maps.app.goo.gl/cABjWegeRbtynwe2A">56, Sathyadev Avenue, MRC Nagar, Chennai, 600 028.</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">

                <div class="text-center copyrights col-lg-12">
                    <p>© 2024 Geeniti Systems Pvt Ltd | All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>