<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" (click)="logoRefresh($event)">
                    <img src="../../../../assets/images/Geeniti Logo.png" height="50px" width="100px" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()" aria-label="toggle-bar">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>

                <!-- <div class="" id="navbarSupportedContent"> -->
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li>
                            <a class="navbar-brand d-lg-block d-none hide-on-mobile" (click)="logoRefresh($event)">
                            <img style="cursor: pointer !important;" src="../../../../assets/images/Geeniti Logo.png" height="75px" width="150px" alt="logo">
                        </a>
                        </li>

                            <li class="nav-item"  [class.clicked]="isHomeClicked">
                                <a href="javascript:void(0)" class="nav-link" (click)="scrollToHomeSection(); toggleClass()">Home</a>
                            </li>

                            <li class="nav-item" [class.clicked]="isAboutClicked">
                                <a (click)="scrollToAboutSection(); toggleClass()" class="nav-link" >About</a>
                            </li>
                            <li class="nav-item" [class.clicked]="isServicesClicked">
                                <a (click)="scrollToServicesSection(); toggleClass()" class="nav-link">Services</a>
                            </li>
                            <li class="nav-item" [class.clicked]="isWhyUsClicked">
                                <a (click)="scrollToWhyUsSection(); toggleClass()" class="nav-link">Why Us</a>
                            </li>
                            <li class="nav-item" [class.clicked]="isContactClicked">
                                <a (click)="scrollToContactSection(); toggleClass()" class="nav-link">Contact</a>
                            </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>