import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, Scroll } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router,
    private viewportScroller: ViewportScroller
    ) { }
  scrollToServicesSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const servicesSection = document.getElementById('servicesSection');
        if (servicesSection) {
          servicesSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
}
scrollToAboutSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const aboutSection = document.getElementById('aboutSection');
        if (aboutSection) {
          aboutSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
  }
  scrollToWhyUsSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const whyUsSection = document.getElementById('whyUsSection');
        if (whyUsSection) {
          whyUsSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
  }
  scrollToTestimonialsSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const testimonialsSection = document.getElementById('testimonialsSection');
        if (testimonialsSection) {
          testimonialsSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
  }
  scrollToContactSection() {
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const contactSection = document.getElementById('contactSection');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
  }
  scrollToHomeSection(){
    this.router.navigate(['/home']).then(() => {
      setTimeout(() => {
        const contactSection = document.getElementById('homeSection');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 0);
    });
  }

  ngOnInit(): void {
  }

  logo = '../../../../assets/images/Geeniti Logo half white.png'

}
